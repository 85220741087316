import { defineStore, getActivePinia } from "pinia";
// import axios from "axios"
import client from "@/plugins/axios";

import Cookies from "js-cookie";

export const useAuthStore = defineStore("useAuthStore", {
  state: () => {
    return {
      login: Cookies.get("logedIn"),
      token: Cookies.get("token"),
      loading: false,
      loadingQuestion: false,
      loadingActivePlan: false,
      loadingTokens: false,
      loadingCreateToken: false,
      loadingAddCredits: false,
      cb_loading: false,
      success: false,
      error: null,
      user: null,
      config: null,
      group_searches: [],
      pdfConfig: null,
      restrictions: [],
      chartRange: null,
      graphOptions: {},
      youtubeLinks: [],
      agendaData: [],
      agendaLoading: true,
      messages: [],
      banks: [],
      activePlan: null,
      cb_plans: [],
      cb_subscriptions: [],
      user_tokens: [],
    };
  },
  getters: {
    fullName: (state) =>
      state.user && state.user.firstname && state.user.lastname
        ? `${state.user.firstname} ${state.user.lastname}`
        : "",
    role_id: (state) => state.user?.role_id,
    professional: (state) => state.user?.professional
  },
  actions: {
    async loginUser(credendentials) {
      try {
        this.loginBegin();
        return client.post("auth/login", credendentials).then((response) => {
          return this.loginSuccess(true, response.data.token);
        });
      } catch (err) {
        this.loginFailure(err);
      }
    },
    async logOut() {
      try {
        this.login = undefined;
        this.token = null;
        this.loading = false;
        Cookies.remove("logedIn");
        Cookies.remove("token");
        Cookies.remove("portfolio-active-columns");
        Cookies.remove("basket-active-columns");
        localStorage.remove('config');
        localStorage.clear();
        const pinia = getActivePinia();
        if (!pinia) {
          throw new Error("There is no active Pinia instance");
        }
        pinia._s.forEach((store) => store.$reset());
      } catch (err) {
        console.log(err);
      }
    },

    async forgotPassword(email) {
      try {
        this.loading = true;
        return client
          .post("auth/forgot-password", {
            email: email,
          })
          .then((response) => {
            return response.data;
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        return (this.error = err);
      }
    },

    async resetPassword(formData) {
      try {
        this.loading = true;
        return client
          .post("auth/reset-password", {
            email: formData.email,
            token: formData.token,
            password: formData.password,
            password_confirmation: formData.passwordConfirm,
          })
          .then((response) => {
            return response.data;
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        return (this.error = err);
      }
    },

    async changePassword(formData) {
      try {
        this.loading = true;
        return client
          .post("auth/update-password", {
            password: formData.oldPassword,
            new_password: formData.newPassword,
            new_password_confirmation: formData.repeatNewPassword,
          })
          .then((response) => {
            this.success = true;
            return response.data;
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        this.loading = false;
        return (this.error = err);
      }
    },

    async getUser() {
      try {
        return client.get("auth/user").then((response) => {
          const searches = {};
          const user_group_searches = response.data.data.user_group.searches;
          user_group_searches.map(item => {
            if (item?.search) {
              searches[item.search] = {
                default: true,
                limits: [0, 1],
              }
            }
          });
          this.group_searches = searches;
          this.user = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getConfig() {
      try {
        return client.get("auth/config").then((response) => {
          this.config = response.data.data;
          this.pdfConfig = response.data.data["pdf_config"];

          const professionalRestriction = this.config.restricted_functionality.find(item => item.professional === ( this.user.professional === 1 ? 1 : 0));
          const restricted_functionality = professionalRestriction?.restrictions.filter(item => {
            let res = false;
            if (item?.role_id === this.user?.role_id || item.role_id === 0) {
              if (!item.countries) res = true;
              else if(item?.countries.includes(this.user?.country) || item?.countries.length === 0) {
                res = true;
              }
            }
            return res;
          });
          this.chartRange = response.data.data["data_since"];
          const restrict_functions = restricted_functionality?.flatMap(rule => rule.functionalities.map(item => {
            const specialKey = Object.keys(item)[0];
            // if (specialKey === 'graph') {
            //   this.chartRange = item[specialKey];
            // }
            return !item[specialKey] && specialKey;
          }));
          console.log("restrict_functions: ", restrict_functions)
          this.restrictions = restrict_functions || [];
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getWeeklyAgenda() {
      try {
        this.agendaLoading = true;
        return client
          .get(`/weekly-agenda`)
          .then((response) => {
            this.agendaData = response.data;
            this.agendaLoading = false;
          });
      } catch (err) {
        this.agendaLoading = false;
        return (this.error = err);
      }
    },

    async getYoutubeLinks() {
      try {
        this.loading = true;
        return client
          .get('/youtube_links')
          .then((response) => {
            this.youtubeLinks = response.data;
          });
      } catch (err) {
        this.loading = false;
        return (this.error = err);
      }
    },

    async getBanks() {
      try {
        this.loading = true;
        return client
          .get('/banks')
          .then((response) => {
            this.banks = response.data.data;
          });
      } catch (err) {
        this.loading = false;
        return (this.error = err);
      }
    },

    async editUser(newUserData) {
      try {
        this.loading = true;
        return client
          .patch("auth/update-user", newUserData)
          .then((response) => {
            this.user = response.data.data;
            this.success = true;
            return response.data.data;
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        this.loading = false;
        return (this.error = err);
      }
    },

    async getChatHistory() {
      try {
        return client.get("chat/history").then(response => {
          this.messages = response.data.data?.map(msg => ({
            sender: msg.sender === 1 ? 'me' : 'bot',
            content: (msg?.type === 'message' || msg?.sender !== 1) ?
              msg.content :
                msg?.is_positive ? '👍' :
                  '👎',
            datetime: new Date(msg.timestamp)
          })) || [];
        })
      } catch (err) {
        console.log("Error: ", err);
        return (this.error = err);
      }
    },

    async askQuestion(question, feedback) {
      try {
        this.loadingQuestion = true;
        return await client
          .post("chat/send-question", {
            question,
            feedback: feedback ? 1 : 0
          }, {
            timeout: 60000
          });
      } catch (err) {
        this.loadingQuestion = false;
        return (this.error = err)
      } finally {
        this.loadingQuestion = false;
      }
    },

    async getActivePlan() {
      try {
        this.loadingActivePlan = true;
        return client
          .get("auth/active-plan")
          .then((response) => {
            this.loadingActivePlan = false;
            this.activePlan = response.data.activePlan;
          });
      } catch (err) {
        console.log("Error>>>", err);
        this.loadingActivePlan = false;
        return (this.error = err);
      }
    },

    loginBegin() {
      this.loading = true;
    },
    loginSuccess(data, token) {
      console.log("login data:", data, "token:", token);
      this.loading = false;
      Cookies.set("logedIn", data);
      Cookies.set("token", token);
      this.login = data;
      this.token = token;
    },

    loginFailure(err) {
      console.log("Error: ", err);
      this.loading = false;
      this.error = err;
    },
    logAction(action) {
      try {
        return client
          .post('/action-log', { action });
      } catch (err) {
        console.log("Err => ", err);
        this.error = err;
      }
    },
    loginChargebee() {
      try {
        return client.get('/auth/chargebee');
      } catch (err) {
        console.log("Err => ", err);
        this.error = err;
      }  
    },
    getTokens() {
      try {
        this.loadingTokens = true;
        return client.get('/auth/user-tokens').then(res => {
          this.user_tokens = res.data.tokens;
          this.loadingTokens = false;
        });
      } catch (err) {
        console.log("Err => ", err);
        this.loadingTokens = false;
        this.error = err;
      }
    },
    createToken() {
      try {
        this.loadingCreateToken = true;
        return client.post('/auth/create-token').then(res => {
          this.user_tokens = res.data.tokens;
          this.loadingCreateToken = false;
        });
      } catch (err) {
        this.loadingCreateToken = false;
        console.log("Err => ", err);
        this.error = err;
      }
    },
    purchaseCredits(token, amount) {
      try {
        this.loadingAddCredits = true;
        return client.post('/auth/add-credits', { token, amount }).then(res => {
          this.loadingAddCredits = false;
          this.user_tokens = res.data.tokens;
          this.logAction(`User ${this.user.id} has purchased ${amount} credits.`);
        });
      } catch (err) {
        this.loadingAddCredits = false;
        console.log("Err => ", err);
        this.error = err;
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "config",
        storage: localStorage,
      },
    ],
  },
});
