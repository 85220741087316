export default {
  brokvisor: "Brokvisor",
  mobile_message:
    "Hello, this resolution is currently not available, please access from a laptop, tablet or larger screen. Sorry for the inconvenience.",
  generic: {
    buttons: {
      add: "Add",
      replace: "Replace",
      add_to_basket: "Add to basket",
      search: "Search",
      load_wallet: "Open portfolio",
      search_funds: "Search funds",
      switch_to_simple: "Switch to simple view",
      generate_portfolio: "Generate portfolio",
      switch_simple_mode: "Simple view",
      switch_advance_mode: "Advanced view",
      portfolio: "Portfolio",
      basket: "Basket",
      loading: "Loading...",
      reset_filters: "Return to default values",
      continue: "Continue",
      select_portfolio: "Select(s) portfolio(s)",
      option: "Option",
      family: "Add class",
      benchmark: "Graph next to Portfolio",
      cancel: "Cancel",
      pay: "Pay",
      ok: "Ok",
      portfolio_summary: "Portfolio Summary",
      asset_basket: "Asset Basket",
      asset_portfolio: "Portfolio Assets",
      portfolios : "Portfolios",
      graph_view: "Comparison Chart",
      funds_view: "Funds Chart",
      fix_weights: "Fix",
      all: "All",
      remove_search: "Remove search",
      save: "Save",
      open_portfolio_control: "Open portfolio dashboard",
      show_portfolio: "Show Portfolio",
      close: "Close",
      purchase: "Purchase",
      portfolio_actions: {
        'open': "Open",
        'edit': "Parameters",
        'remove': "Delete",
        'rename': "Rename",
        'send_mail': "Email",
        'will_receive_mail': "You will receive a summary email in the next few minutes."
      },
      portfolio_actions_tooltips: {
        'open': "Open selected portfolio",
        'edit': "Change portfolio parameters",
        'remove': "Delete selected portfolio",
        'rename': "Rename selected portfolio",
        'send_mail': "Send portfolio summary mail",
      },
    },
    titles: {
      active_plan: "Your active plan",
      select_family: "Select the family you want to add to the basket",
      predefined_search: "Selection of funds from our AI",
      filter_criteria: "Select criteria to filter",
      limit_risk: "Select categories",
      limit_control: "Select limits",
      optimize_portfolio: "Optimize portfolio",
      fix_weights: "Fix weights",
      generate_portfolio: "Generate portfolio",
      load_portfolio: "My portfolios",
      load_portfolio_text: "Select one or several portfolios to follow them on Brokvisor.",
      import_portfolio: "Import portfolio",
      search_funds: "Search Funds",
      search_stocks: "Search Stocks",
      search_cryptos: "Search Cryptos",
      search_benchmark: "Search Indices",
      search_etfs: "Search ETF",
      search_pensions: "Search Pensions",
      countries: "Countries",
      sectors: "Sectors",
      industries: "Industries",
      units: "Units",
      operator: "Operator",
      changes_ok: "Changes applied",
      new_password_message: "Link sent",
      error: "Error",
      user: "User",
      select_columns: "Customize table",
      select_columns_tooltip: "Customize the columns to be shown in the table.",
      select_deselect: "Select/Deselect all",
      category: "Category",
      percent: "%",
      select_search: "Select a search",
      top: "Top",
      bests_modal_title: "Results found %{total} results",
      clean_basket: "Clean basket before adding new",
      elements_by_page: "Elements by page",
      available_text: "Please select from the available columns",
      no_available_text: "All columns have already been selected.",
      no_fund_for_graph: "Select in the basket up to 20 assets to plot",
      table_length: "results",
      weekly_agenda: "Weekly economic calendar",
      see_all_faqs: "See all FAQs",
      see_less_faqs: "See less FAQs",
      no_opened_portfolio: "You don't have any opened portfolio.",
      chat_input_placeholder: "Ask your question here...",
      edit_portfolio_params: "Edit Portfolio Parameters",
      delete_portfolios: "Are you sure you want to delete this portfolio?",
      portfolio_control_panel: "Portfolio Dashboard",
      price: "Price",
      period: "Period",
      currency_code: "Currency code",
      and: "and",
      agreement_label: "I accept the ",
      terms_of_service: "Terms of Service",
      privacy_policy: "Privacy Policy"
    },
    page_infos: {
      import_portfolio: "You can generate your portfolios from the csv on this page.",
      advanced_generate_portfolio: "You can generate your portfolios here with some limits and using the advanced AI technology of ours.",
      optimize_portfolio: "Optimize your current portfolio using better risks and limits. You are able to use the already saved limits here as well.",
      fix_weights: "Fix your current portfolio's weights by adjustting manually. Please try to observe the simulation columns from the below table.",
    },
    disclaimer: "The information contained in this publication has not been approved by the National Securities Market Commission. This publication is carried out solely for informational purposes. Neither this publication nor its content constitutes an offer, solicitation, or invitation to buy, an agreement, or commitment to invest or make any kind of decision. This publication does not constitute specific investment advice to buy or sell any investment or subscribe to any investment contract or any other financial service, nor does it imply any tax advice or recommendation. We recommend that any tax-related decision be consulted with lawyers and/or tax advisors. You should not use this publication as a basis for making your financial decisions. All forecasts, opinions, estimates, and projections contained in this publication represent the judgment of BROKVISOR or third parties of recognized prestige and are provided solely for illustrative purposes. Such forecasts, opinions, estimates, and projections involve known and unknown risks, uncertainties, and other factors that may cause actual results, performance, or achievements to be materially different from the future results, performance, or achievements expressed or implied in such forecasts, opinions, estimates, and projections. Past performance is not a reliable guide to future performance. The value of investments and the income derived from them may go down or up, and you may not recover the amount invested.",
    disclaimer_morning_star: "© 2024 Morningstar. All Rights Reserved. The information contained herein: (1) is proprietary to Morningstar and/or its content providers; (2) may not be copied or distributed; and (3) is not warranted to be accurate, complete or timely. Neither Morningstar nor its content providers are responsible for any damages or losses arising from any use of this information. Pastperformance is no guarantee of future results.",
    empty_basket_title: "Your asset basket is empty",
    empty_basket_desc: "Search and analyze the best funds and assets. Take advantage of our predefined searches so that our AI chooses the most convenient strategy for you at this moment. In your basket you can analyze them and create a pdf to see the details of all the assets together.",
    no_selected_portfolio: "You don't have any opened portfolio",
    portfolio_params: {
      risk: "Risk",
      depository: "Depository",
      group: "Portfolio group",
      include_benchmark: "Include benchmark",
      no_rebalance: "Automatic rebalance",
      include_compositions: "Include compositions",
      created_at: "Registration Date",
      updated_at: "Last Rebalancing Date",
      send_mail_every: "Send emails every",
      days_between_reviews: "days between reviews",
      value_at: "Value at",
      delete_positions: "Delete positions",
      true: "Yes",
      false: "No",
      to: "To",
      confirm_delete_positions: "Are you sure to delete these positions?",
      value_at_options: {
        "LM": "Last Month Close",
        "LD": "Last Date Close"
      },
      send_mail_options: {
        "none": "None",
        "d": "Day(s)",
        "w": "Week(s)",
        "m": "Month(s)",
      }
    },
    filter_conditions: {
      equals: 'Equal',
      not_equal: 'Not equal',
      blank: 'Blank',
      not_blank: 'Not blank',
      empty: 'Choose one',
      less_than: 'Less than',
      greater_than: 'Greater than',
      less_than_or_equal: 'Less than or equal to',
      greater_than_or_equal: 'Greater than or equal to',
      in_range: 'Between',
      in_range_start: 'From',
      in_range_end: 'To',
      contains: 'Contains',
      not_contains: 'Not contain',
      starts_with: 'Starts with',
      ends_with: 'Ends with',
    },
    options: {
      select_one: "Select one",
      unlimmited: "Unlimmited",
      more_that: "(>) Greater than",
      less_that: "(<) Less than",
      same_as: "(=) Equal to",
    },
    risk_options: {
      Conservador: "Conservative",
      Moderado: "Moderate",
      Equilibrado: "Balanced",
      Dinamico: "Dynamic",
      Agresivo: "Aggressive",
      Defensivo: "Defensive"
    },
    toast: {
      session_expired_title: "Session expired",
      session_expired_description: "You need to log in again",
      generic_error_title: "Error",
      generic_error_description: "An error occurred during data recovery",
      generic_warning_title: "Warning",
    },

    forms: {
      tkr_name: "Tkr/Name",
      company: "Company",
    },
  },
  chart: {
    range: {
      yearToDate: 'YTD',
      all: 'All'
    },
    zoom: 'Periods'
  },
  agenda: {
    forecast: "Forecast",
    event: "Event",
    currency: "Currency",
    region: "Region",
    hour: "Hour",
    actual: "Actual",
    previous: "Previous"
  },
  faqs: {
    types: {
      'Portfolio': "Portfolio",
      'General': "General",
      'Users': "Users"
    }
  },
  pdf: {
    portfolio_report_title: "Portfolio Report",
    asset_report_title: "Asset Report",
    page: "Page",
    of: "of"
  },
  generate_portfolio: {
    text: "Create the best portfolios using our Machine Learning technology. You will be able to create countless portfolios, adjusting multiple variables, which will make each portfolio unique for each client, according to their needs and preferences.",
    slider_ranges: {
      dont_like: "I do not like",
      like_bit: "I like little",
      neutral: "Neutral",
      like: "I like it",
      very_like: "I like it very much",
    },
    region_slider_ranges: {
      "Europe ++": "Europe ++",
      "Europe +" : "Europe +",
      "Neutral": "Neutral",
      "Usa +": "USA +",
      "Usa ++" : "USA ++",
    },
    size_slider_ranges: {
      "Pequeñas ++": "Small ++",
      "Pequeñas +": "Small +",
      "Neutral": "Neutral",
      "Grandes +": "Big +",
      "Grandes ++": "Big ++"
    },
    factor_slider_ranges: {
      "Value ++":"Value ++",
      "Value +": "Value +",
      "Nuetral": "Nuetral",
      "Growth +" : "Growth +",
      "Growth ++" : "Growth ++"  
    },
    risk_slider_ranges: {
      Conservador: "Conservative",
      Moderado: "Moderate",
      Equilibrado: "Balanced",
      Dinamico: "Dynamic",
      Agresivo: "Aggressive",
      Defensivo: "Defensive"
    },
    duration_slider_ranges : {
      "Corto ++" : "Short ++",
      "Corto +":"Short +",
      "Neutral": "Neutral",
      "Largo +":"Large +",
      "Largo ++":"Large ++"
    },
    ig_slider_ranges : {
      "High Yield ++":"High Yield ++",
      "High Yield +" :"High Yield +",
      "Neutral" :"Neutral",
      "Investment Grade +": "Investment Grade +",
      "Investment Grade ++" : "Investment Grade ++"
    },
    region:"Region",
    factor: "Factor",
    risk: "Profile",
    size:"Capitalization",
    investmentGrade : "Investment Grade",
    duration: "Duration",
    north_america: "North America",
    emerging: "Emerging",
    developed_europe: "Developed Europe",
    price_benefit: "P/E",
    concentrated_diversified: "Concentrated / Diversified",
    invest_amount: "Amount to invest",
    only_my_bank: "Show funds available for purchase in my company",
    limits: {
      "Equity/Bonds": "Equity/Bonds",
      "Value/growth indicators": "Ratios",
      "Fixed Income Parameters": "Fixed income",
      equity_comp: "Equity composition",
      fi_comp: "Fixed income composition",
      Equity: "Equity",
      Bonds: "Bonds",
      Sectors: "Equity Sectors ",
      consumer_cyclical: "Consumer cyclical",
      consumer_defensive: "Consumer defensive",
      energy: "Energy",
      industrials: "Industrials",
      real_estate: "Real estate",
      basics_materials: "Commodities",
      healthcare: "Healthcare",
      financial_service: "Financial services",
      utilities: "Utilities",
      communication_services: "Communication services",
      technology: "Technology",
      per: "P/E",
      p_value: "P/Value Book",
      p_cf: "P/Cash flow",
      p_sales: "P/Sales",
      dividend: "Dividend",
      region: "Equity Regions",
      asia_developed: "Developed Asia",
      asia_emerging: "Emerging Asia",
      australasia: "Australasia",
      europe_developed: "Developed Europe",
      europe_emerging: "Emerging Europe",
      japan: "Japan",
      latin_america: "LATAM",
      north_america: "North America",
      uk: "United Kingdom",
      africa_middle_east: "Africa and Middle East",
      estimated_duration: "Modified Duration",
      effective_duration: "Effective duration",
      inv_grade: "Investment grade",
      corp: "Corporate",
      public: "Government",
      securitised: "Securitized",
    },
  },
  generate_portfolio_advanced: {
    text: "Create the best portfolios using our Machine Learning technology. You will be able to create countless portfolios, adjusting multiple variables, which will make each portfolio unique for each client, according to their needs and preferences.",
    categories: "Categories",
    max_weight: "Max weight per fund",
    apply_risk: "Apply risk",
    delete_weight_zero: "Eliminate weights 0",
    limit_weight_group: "Limit weights per group",
    limit_compositions: "Limit compositions",
    weights: "Weights",
    compositions: "Compositions",
    invest_amount: "Amount to invest",
    invest_amount_placeholder: "0",
    enter_portfolio_name: "Insert a portfolio name",
  },
  import_portfolio: {
    drag_file: "Drag the portfolio file to here",
    only_csv: "Only compatible with csv files",
    browse_files: "Explore computer",
    import_portfolio: "Import portfolios",
    download_standard: "Download Excel template",
    file_uploaded: "%{filename}  file uploaded successfully.",
    file_upload_failed: "%{filename}  file upload failed.",
    files_upload_error: "An error occurred while uploading one or more files",
    balances: "Balances",
    transactions: "Transactions",
    new_true: "Yes",
    new_false: "No",
    is_new_portfolio: "Is it a new portfolio?",
    select_portfolio: "Select portfolio",
    portfolio_risks: "Portfolio risk level",
    include_benchmark: "Include index",
    portfolio_type: "Portfolio type",
    type_transactions: "Transactions",
    optional: "Optional",
    type_statement: "Statement",
    modal_ok: "Ok",
    modal_cancel: "Cancel"
  },
  portfolio_limits: {
    new_limit: "Create New References",
    saved_limits: "Saved Preferences",
    input_placeholder: "Limit Name",
    save_limit: "Save limits",
    saved_limits_placeholder: "Saved limits",
    save: "Save",
    open_limit: "Open",
    rename_limit: "Rename",
    delete_limit: "Delete",
    confirm_delete: "Are you sure you want to delete selected limit?",
    select_any_limit: "No limit selected to be deleted.",
  },
  search_funds: {
    text: "Search for and analyze the best funds. Take advantage of our predefined searches so that our AI can choose the most suitable strategy for you at this moment. In your basket, you can analyze them and create a PDF to view the details of all the funds together.",
    search_asset: "Name or ISIN",
    funds_by_company: "Top Holdings",
    search_by_company: "By companies",
    select_stored_search: "AI selection",
    select_filter_criteria: "Custom",
    add_full_list: "Add Full List",
    add_all: "Add all to basket",
    confirm_add_all: "Are you sure to add all items without saving?",
    change_criteria: "Change criterias",
    portfolio_funds: "Funds in my portfolios",
    auto_filter: "Show funds available for purchase in my company",
    saved_search: "Saved searches",
    rename_search: "Save search",
    new_search: "New search",
    execute_search: "Execute",
    edit_search: "Edit",
    save_search_error: "Error: A search with that name already exists.",
    save_search_empty_name : "Error: Empty names are not allowed",
    funds_search_criteria: {
      foreign_exchange: "Currencies",
      cover: "Hedge",
      morning_star: "Stars Morningstar",
      patrimony_higher_than: "Fund size greater than or equal to:",
      million_e: "(million €)",
      passive_only: "Only indexed funds",
      preferred_agencies_only: "Only preferred managers",
      preferred_funds_only: "Only preferred funds",
      comparable_funds: "Funds available for purchase in my company",
      true: "Yes",
      false: "No",
      distribution: "Distribution",
      leverage: "Leverage",
      inverse: "Inverse"
    },
    filter_criteria_category: {
      asset_type: "Asset type",
      area: "Country/region",
      topics: "Topics",
      factor: "Factor",
      size: "Size",
      fixed_income_category: "Fixed income category",
      term: "Term",
      mixed_assets_without_data: "Mixed assets sin data",
      mixed_assets: "Mixed assets",
      alternatives_without_data: "Alternatives sin data",
      alternatives: "Alternatives",
      country: "Country/Region",
      sector: "Sector",
      fi_type: "Fixed income category",
      region: "Country/Region",
      mix_type: "Mixed assets",
      alternative_type: "Alternatives",
      assets_type: {
        Equity: "Equity",
        "Money Market": "Money Market",
        Bond: "Bond",
        "Mixed Assets": "Mixed Assets",
        Alternative: "Alternatives",
        Commodity:"Commodity"
      },
    },
    filter_criteria: {
      categorical_filters: "Search",
      percentage_filters: "Composition filters",
      reset_filters: "Reset filters",
      search: "Search",
      add_to_basket: "Add to basket",
    },
    select_stored_options: {
      Alternative: "Alternatives",
      "Alternatives all EUR": "All alternatives EUR",
      "Alternatives Cons and Moderate":
        "Alternatives Conservative and Moderate",
      "Alternatives Dinamic and Agresive":
        "Dynamic and aggressive alternatives",
      "Mixed Assets": "Mixed assets",
      "Mixed assets EUR": "Mixed assets EUR",
      "Mixed assets Cons and Moderate":
        "Mixed assets Conservative and Moderate",
      "Mixed assets Dinamic and Agresive":
        "Dynamic and aggressive mixed assets",
      "Money Market": "Money market",
      "Money Market USD": "Money market USD",
      "Money market EUR": "Money market EUR",
      "Fixed Income": "Fixed Income",
      "Fixed income convertible EUR": "Convertible fixed income EUR",
      "Fixed income corporate": "Corporate fixed income",
      "Fixed income short term EUR": "Short-term fixed income EUR",
      "Fixed income short term USD": "Short-term fixed income USD",
      "Fixed income emerging global": "Global emerging fixed income",
      "Fixed income emerging countries and regions":
        "Fixed income emerging countries and regions",
      "Fixed income Europe EUR": "Fixed income Europe EUR",
      "Fixed income global EUR": "Global fixed income EUR",
      "Fixed income global EUR and HDG": "Global fixed income EUR and HDG",
      "Fixed income global USA": "Global fixed income USD",
      "Fixed income government global and regional investment grade":
        "Fixed income government global and regional investment grade",
      "Fixed income high yield": "High yield fixed income",
      "Fixed income inflation EUR": "Fixed income inflation EUR",
      "Fixed income subordinates": "Subordinated fixed income",
      "Fixed income USA EUR": "Fixed income USA EUR",
      "Fixed income all": "All fixed income",
      Equity: "Equity",
      "Equity Asia": "Equity Asia",
      "Equity Emerging Markets global": "Equity global Emerging Market",
      "Equity Europe": "Equity Europe",
      "Equity global EUR": "Global Equity EUR",
      "Equity global USD": "Global Equity USD",
      "Equity Japan": "Equity Japan",
      "Equity countries and smaller regions":
        "Equity countries and smaller regions",
      "Equity sectors USD": "Sectors Equity USD",
      "Equity sectors": "Sectors Equity",
      "Equity USA": "Equity USA",
      "Equity USA USD": "Equity USA USD",
      "Equity all": "Equity all",
      "Commodities": "Commodities",
      "Equity emerging countries": "EQ Emerging Countries",
      "Equity Europe small": "EQ Europe Small",
      "Equity USA small": "EQ USA Small",
      "Equity Europe Growth": "EQ Europe Growth",
      "Equity USA Growth": "EQ USA Growth",
      "Equity global Growth" : "EQ GLOB GROWTH",
      "Equity global small" : "EQ GLOB SMALL",
    },
    percent_filter: {
      "Equity/Bonds": "Equity/Bonds",
      Sectors: "Sectors",
      "Value/growth indicators": "Value/growth indicators",
      region: "Region",
      "Fixed Income Parameters": "Fixed income parameters",
      equity_comp: "Equity composition",
      fi_comp: "Fixed income composition",
      consumer_cyclical: "Consumer cyclical",
      consumer_defensive: "Consumer defensive",
      energy: "Energy",
      industrials: "Industrial",
      real_estate: "Real estate",
      basics_materials: "Commodities",
      healthcare: "Healthcare",
      financial_service: "Financial services",
      utilities: "Utilities",
      communication_services: "Communication services",
      technology: "Technology",
      per: "P/E",
      p_value: "P/Value Book",
      p_cf: "P/Cash flow",
      p_sales: "P/Sales",
      dividend: "Dividends",
      asia_developed: "Developed Asia",
      asia_emerging: "Emerging Asia",
      australasia: "Australasia",
      europe_developed: "Developed Europe",
      europe_emerging: "Emerging Europe",
      japan: "Japan",
      latin_america: "LATAM",
      north_america: "North America",
      uk: "United Kingdom",
      africa_middle_east: "Africa and Middle East",
      estimated_duration: "Modified Duration",
      effective_duration: "Effective duration",
      inv_grade: "Investment grade",
      corp: "Corporate",
      public: "Government",
      securitised: "Securitized",
    },
    select_family_columns: {
      fees: "Fees",
      currency: "Currency",
      hedge: "Hedge",
      base_currency: "Base currency",
      min_ini_purchase: "Minimum purchase",
      distribution: "Distribution",
      isin: "ISIN",
      name: "Name",
      tradable: "Tradable",
      yes: "Yes",
      no: "No",
    },
  },
  portfolio_funds_modal: {
    placeholder: "Select option",
    all: "All",
    add_all: "Add all to basket",
  },
  search_stocks: {
    text: "",
    search_asset: "Name or ISIN",
    select_filter_criteria: "Custom",
    filters: {
      "Cap(M)": "Marketcap",
      "P/ER(%)": "PER",
      "Yield(%)": "Dividend yield",
    },
  },
  search_cryptos: {
    text: "",
    search_asset: "Cryptos Search",
    asset_dropdown_placeholder: "Select Crypto",
  },
  search_benchmarks: {
    text: "Find the index that best suit your portfolio type, from standard index to custom index based on your profile.",
    search_asset: "Name",
    search_assets: "Find an index",
    asset_region: "Region",
    asset_category: "Category",
    asset_dropdown_placeholder: "Find index",
    asset_region_category: "First select region and category",
  },
  search_pensions: {
    asset_region: "Region",
    pension_type: "Type",
    asset_dropdown_placeholder: "Find pension",
    asset_region_category: "First select region and category",
  },
  funds_printer: {
    risk_level: "Risk level",
    new_candidates : "New Candidates",
    max_vol: " o vol Max",
    optimize_portfolio: "Optimize portfolio",
    add_to_basket: "Add to basket",
    add_to_basket_tooltip: "Move the selected funds to the basket.",
    add_to_portfolio: "Add to Portfolio",
    add_to_portfolio_tooltip: "Add selected funds to the portfolio.",
    portfolio_report: "Portfolio Report",
    pdf_report: "Portfolio",
    asset_report: "Asset",
    basket_report: "Asset Report",
    basket_report_tooltip: "Generate PDF report with the selected funds.",
    reports: "Reports",
    reports_tooltip: "Generate PDF reports with the portfolio or funds.",
    create_graph: "Chart",
    delete_selection: "Remove selection",
    delete_selection_tooltip: "Remove the selected items from the table.",
    delete_all: "Remove all",
    delete_all_tooltip: "Remove all items from the table.",
    delete_all_confirm: "¿Are you sure to remove everything?",
    plot_values: "Highlight selected fund on the chart",
    neutral: "Neutral",
    added_funds: "Assets added to basket",
    added_benchmarks: "Index added",
    added_cryptos: "Cryptos added to basket",
    cannot_family: "You can only search by fund family",
    added_pensions: "Pensions added to basket",
    weight_sum: "Sum",
    release: "Release",
    discard: "Discard",
    select_all_switch_tooltip: "Select or deselect all."
  },
  message: {
    hello: "Hello",
    changes_applied: "The changes have been applied correctly",
    failed_pdf: "PDF generation failed",
    ask_funds: "Please select any funds.",
    too_many_assets: "Please select less than 20 assets to graph.",
    generate_pdf_with_only_funds: "Generating PDF with only fund assets.",
    unable_delete: "Unable to delete positions.",
    unable_delete_some: "Unable to delete some positions.",
  },
  welcome: {
    title: "Bring clarity to every investment decision",
    market_snapshot: "Start using brokvisor",
    news: "Coming soon",
    text: "Use one of our pre-filtered stock lists created by our IA to find your next best investment. Or find and compare securities using comprehensive performance and valuation metrics alongside.",
    video_title: "Learn how to get the best out of Brokvisor with our tutorials"
  },
  sidebar: {
    portfolio_management: "Portfolio management",
    portfolio_generate: "Generate portfolio",
    my_portfolio: "My portfolios",
    portfolio_import: "Import portfolio",
    search_assets: "Asset Search",
    funds: "Funds",
    stocks: "Stocks",
    cryptos: "Cryptos",
    benchmark_search: "Indices",
    etf_search: "ETFs",
    pensions: "Pensions",
    go_basket: "Basket",
    go_portfolio: "Portfolio"
  },
  auth: {
    already_have_account: "¿Already have an account?",
    sign_in: "Login",
    dont_have_account: "Don't have an account?",
    sign_up: "Register now",
    sign_in_to: "Login to",
    sign_up_to: "Register in",
    enter_your_data: "Enter your data below",
    username: "E-mail",
    password: "Password",
    cb_account: "Subscription management",
    purchase_credits: "API KEY management",
    api_keys: "API Keys",
    manage_keys: "Manage your keys or create new ones",
    create_key: "Create Key",
    keep_me_logged: "Remember me",
    forgot_password: "Forgot password?",
    loading: "Loading...",
    we_will_help_you: "We will help you to restore it and get back to normal.",
    notice:
      "If you have an account, we will send you an email with a reset link..",
    recover_password: "Password recovery",
    want_new_password: "Change password",
    api_operation: "Operation",
    api_cost: "Cost",
    new_password: "New password",
    repeat_new_password: "Repeat new password",
    new_password_indications: "Write a new password for your account",
    change_password_action_button: "Change my password",
    return_to: "Back to",
    profile_details: "Account Details",
    help_center: "soporte@brokvisor.com",
    copy_to_clipboard: "The support email has been copied to the clipboard.",
    copy_token: "The token copied to the clipboard.",
    credit_amount_to_purchase: "How many credits are you going to buy?",
    confirm_payment: "Do you want to complete the payment?",
    log_out: "Log Out",
    name: "Name",
    lastname: "Lastname",
    email: "E-mail",
    address: "Address",
    country: "Country",
    city: "City",
    state: "State",
    zipcode: "Zip Code",
    phone: "Phone number",
    invoice_city: "Invoice city",
    invoice_country: "Invoice country",
    invoice_zip: "Invoice zip",
    invoice_address: "Invoice address",
    vat_number: "VAT number",
    bank: "Company for Funds",
    edit_account: "Change personal information",
    save_changes: "Save changes",
    broker: "Manager",
    confirmed_purchase: "Your purchase is confirmed, you can find the invoice in subscription management",
    required_field_error: "This field is required",
    login_error_title: "",
    login_error_text: "Error: Incorrect email or password please try again.",
    forgot_password_error: "Something has gone wrong. Please try again.",
    forgot_password_success:
      "We have sent an email with a reset link. Please check your inbox.",
    forgot_password_success_toast_title: "Password changed",
    forgot_password_success_toast_message:
      "Your password has been changed successfully",
    rules: {
      new_password: "Please enter a new password",
      repeat_new_password: "Please repeat your new password",
      passwords_dont_match: "Passwords do not match",
      email: "Please enter your email address",
      password: "Please enter your password",
    },
  },
  portfolio_overview: {
    title: "Portfolio summary",
    save_portfolio: "Save portfolio",
    save_portfolio_label: "Save in Database",
    clean_portfolio: "Clean screen",
    portfolio_name: "Portfolio name",
    saved_portfolio_name: "Name to save",
    ok_order: "Order generated",
    save_portfolio_message: "Are you sure you want to keep your portfolio?",
    clean_portfolio_message: "Are you sure about cleaning your portfolio?",
    generate_order: "Generate order",
    redirect_no_portfolio: "No portfolio is loaded",
    save_confirm: "Confirm to update?",
    and: " y ",
    composition_limits_with_non_funds: "Composition limits are not compatible with assets other than funds.",
    columns: {
      EUR: "EUR",
      "Rent 1 sem": "Yield 1W",
      "Rent 2 sem": "Yield 2W",
      CASH: "CASH",
      portfolio: "Portfolio",
      monetary: "Monetary",
      fixed_income: "Fixed Income",
      variable_income: "Equity",
      other: "Other",
      name: "Name",
      rent_ytd: "Yield YTD",
      rent_1m: "Yield 1M",
      rent_3m: "Yield 3M",
      rent_1a: "Yield 1Y",
      rent_3a: "Yield 3Y",
      vol_44: "Vol 2M",
      vol_260: "Vol 1Y",
      vola_12m: "Vol 12M",
      sharpe_12m: "Sharpe 12M",
      mxdd_2m: "MaxDD 2m",
      mxdd_44: "MaxDD 2M",
      mxdd_260: "MaxDD 1Y",
      YTD: "YTD EUR",
      "Rent a => 5/2023": "Yield to => 5/2023",
      "Rent 1m": "Yield 1M",
      "Rent 3m": "Yield 3M",
      "Rent 1A": "Yield 1Y",
      "Rent 3A": "Yield 3Y",
      "Vola 12m": "Vol 12M",
      "Sharpe 12m": "Sharpe 12M",
      "mxdd 2m": "MaxDD 2M",
      Sortino40: "Sortino 40D",
      "Sortino 2m": "Sortino 2M",
      Rent_2023: "Yield 2023",
      false: "No",
      true: "Yes",
      weight: "Weight",
      tooltips: {
        EUR: "EUR",
        "Rent 1 sem": "Yield 1W",
        "Rent 2 sem": "Yield 2W",
        CASH: "CASH",
        portfolio: "Portfolio",
        monetary: "Monetary",
        fixed_income: "Fixed Income",
        variable_income: "Equity",
        other: "Other",
        name: "Name",
        rent_ytd: "Yield YTD",
        rent_1m: "Yield 1M",
        rent_3m: "Yield 3M",
        rent_1a: "Yield 1Y",
        rent_3a: "Yield 3Y",
        vol_44: "Vol 2M",
        vol_260: "Vol 1Y",
        vola_12m: "Vol 12M",
        sharpe_12m: "Sharpe 12M",
        mxdd_2m: "MaxDD 2m",
        mxdd_44: "MaxDD 2M",
        mxdd_260: "MaxDD 1Y",
        YTD: "YTD EUR",
        "Rent a => 5/2023": "Yield to => 5/2023",
        "Rent 1m": "Yield 1M",
        "Rent 3m": "Yield 3M",
        "Rent 1A": "Yield 1Y",
        "Vola 12m": "Vol 12M",
        "Sharpe 12m": "Sharpe 12M",
        "mxdd 2m": "MaxDD 2M",
        Sortino40: "Sortino 40D",
        Rent_2023: "Yield 2023",  
      }
    },
    headings: {
      "Assets": "Asset allocation",
      chart_regions: "Regions",
      "Asset Type": "Asset Type",
      Regions: "Equity Regions",
      Sectors: "Equity Sectors",
      Size: "Size",
      "Bond parameters": "Bond parameters",
      Countries: "Countries",
      Ratings: "Ratings",
      Coupons: "Coupons",
      Maturity: "Maturity",
      positions: "Positions",
      ratios: "Ratios",
      "Fixed income sectors": "Fixed income sectors",
      "Fixed income corp sectors": "Fixed income corp sectors",
      "Fixed income regions": "Fixed income regions",
      summary: "Summary",
      "Value/growth indicators": "Value/growth indicators",
      portfolio: "Portfolios",
      assets: "Asset allocation",
      "Main positions": "Main positions",
      "Asset Information": "Asset information",
      "Asset compositions": "Asset compositions",
      "Ratios": "Ratios",
      "Returns": "Returns",
      "Risk": "Risk",
      "graph": "Graph"
    },
    invest_amount: "Amount to invest",
    invest_amount_placeholder: "0",
  },
  portfolio_control: {
    description: "You can review & rebalance all of your portfolios from this page.",
    rebalancer: "Rebalance",
    review: "Open portfolio",
    portfolio_table: "Portfolio Table",
    graph_view: "Comparison Chart",
    select_one_fund: "Select only one portfolio.",
    select_any_fund: "Please select at least one portfolio.",
    color_legend: "Legend",
    benchmark_comparisons: "Benchmark comparisons",
    "far below": "far below",
    below: "below",
    similar: "similar",
    above: "above",
    "far above": "far above"
  },
  operands: {
    no_limits: "Unlimited",
    greater_than: "(>) Greater than",
    greater_equal_than: "(≥) Greater than or equal to",
    less_than: "(<) Less than",
    less_equal_than: "(≤) Less than or equal to",
    equal_to: "(=) Equal to",
  },
  amount_units: {
    million: "Million",
    thousand_million: "Billion",
    billion: "Trillion",
  },
  search_categories: {
    "EQ GLOB": "EQ Global",
    "EQ USA": "EQ USA",
    "EQ SECTORS": "EQ Sectors",
    "EQ COUNTRIES": "EQ Countries",
    "EQ EUROPE": "EQ Europe",
    "FI GLOB": "FI Global",
    "FI HY": "FI High Yield",
    "FI GOV": "FI Gov",
    "FI EM": "FI Emerging",
    "FI SHORT EUR": "FI Short EUR",
    "FI CORP": "FI Corp",
    "MM EUR": "Money M EUR",
    "MIX DIN AGR": "Mix Assets High vol",
    "MIX CON MOD": "Mix Assets low vol",
    "ALT DIN AGR": "Alt High vol",
    "ALT CON MOD": "Alt Mix low vol",
    "COMMODITIES": "Commodities",
    "EQ EM COUNTRIES": "EQ Emerging Countries",
    "EQ EUROPE SMALL": "EQ Europe Small",
    "EQ USA SMALL": "EQ USA Small",
    "EQ EUROPE GROWTH": "EQ Europe Growth",
    "EQ USA GROWTH": "EQ USA Growth",
    "FI SUB": "FI Subordinated",
    "FI CONV": "FI Convertible",
    "FI INFLAT": "FI Linked to Inflation",
    "FI EUROPE": "FI Europe",
    "FI EUROPE EX-HY": "FI Europe Not High Yield", 
    "FI USA EX-HY": "FI USA Not High Yield",
    "FI GLOB EUR H": "FI Global EUR HEDGED", 
    "EQ ASIA": "EQ Asia",
    "EQ EM": "EQ Emerging Markets", 
    "EQ JAPAN": "EQ Japan"
  },
  search_category_tooltips: {
    "EQ GLOB": "Global equity funds",
    "EQ USA": "USA equity funds",
    "EQ SECTORS": "Sector equity funds",
    "EQ COUNTRIES": "Equity funds of countries and some regions of minor relevance",
    "EQ EUROPE": "European equity funds including euro zone",
    "FI GLOB": "Global fixed income funds",
    "FI HY": "Global and regional high yield funds",
    "FI GOV": "Government fixed income funds",
    "FI EM": "Emerging market fixed income funds",
    "FI SHORT EUR": "Short-term fixed income funds in euros",
    "FI CORP": "Global and regional corporate bond funds",
    "MM EUR": "Money market funds",
    "MIX DIN AGR": "Aggressive and dynamic mixed funds",
    "MIX CON MOD": "Conservative and moderate profile mixed funds",
    "ALT DIN AGR": "Alternative funds with a dynamic and aggressive profile",
    "ALT CON MOD": "Conservative and moderate profile alternative funds",
    "EQ EUROPE SMALL": "European small-cap equity funds",
    "EQ USA SMALL": "US small-cap equity funds",
    "EQ EUROPE GROWTH": "European growth equity funds",
    "EQ USA GROWTH": "US growth equity funds",
    "FI SUB": "Subordinated fixed income funds",
    "FI EUROPE": "European fixed income funds",
    "EQ EM COUNTRIES": "Equity funds of specific emerging market countries",
    "COMMODITIES": "Commodities funds",
    "FI CONV": "Convertible Fixed Income Funds",
    "FI INFLAT": "Inflation-Linked Fixed Income Funds",
    "FI EUROPE EX-HY": "Europe Not High Yield Fixed Income Funds", 
    "FI USA EX-HY": "USA Not High Yield Fixed Income Funds",
    "FI GLOB EUR H": "Global EUR HEDGED Fixed Income Funds", 
    "EQ ASIA": "Asia equity funds",
    "EQ EM": "Emerging Markets equity funds", 
    "EQ JAPAN": "Japan equity funds" 
  },
  header_tooltips: {
    currency: "Currency class of the fund",
    fi_comp: "% of fixed income in the fund",
    ret_1: "1-day return in EUR",
    ret_5: "1-week return in EUR",
    ret_10: "2-week return in EUR",
    ret_22: "1-month return in EUR",
    ret_44: "2-month return in EUR",
    ret_65: "3-month return in EUR",
    ret_130: "6-month return in EUR",
    ret_260: "1-year return in EUR",
    ret_780: "3-year return in EUR",
    ret_1300: "5-year return in EUR",
    ret_2021: "2021 return in EUR",
    ret_2022: "2022 return in EUR",
    ret_2023: "2023 return in EUR",
    vol_5: "1-week volatility in EUR",
    vol_10: "2-week volatility in EUR",
    vol_22: "1-month volatility in EUR",
    vol_44: "2-month volatility in EUR",
    vol_65: "3-month volatility in EUR",
    vol_260: "1-year volatility in EUR",
    vol_780: "3-year volatility in EUR",
    vol_1300: "5-year volatility in EUR",
    vol_2021: "2021 volatility in EUR",
    vol_2022: "2022 volatility in EUR",
    vol_2023: "2023 volatility in EUR",
    mxdd_5: "Max Drawdown 1 week",
    mxdd_10: "Max Drawdown 2 weeks",
    mxdd_22: "Max Drawdown 1 month",
    mxdd_44: "Max Drawdown 2 months",
    mxdd_65: "Max Drawdown 3 months",
    mxdd_260: "Max Drawdown 1 year",
    mxdd_780: "Max Drawdown 3 years",
    mxdd_1300: "Max Drawdown 5 years",
    mxdd_2021: "Max Drawdown 2021",
    mxdd_2022: "Max Drawdown 2022",
    mxdd_2023: "Max Drawdown 2023",
    equity_comp: "% of equity in the fund",
    fund_type: "Fund category",
    YTLMC: "Closing of the last month",
    name : "Name of the fund",
    hedge : "Currency hedged class",
    distribution : "Class distributing dividends",
    fund_size: "Total fund size",
    isin_tkr: "Identification code",
    family: "Brokvisor number to identify all classes of the same fund", 
    launch_date: "Launch date of the fund",
    pasive: "Index fund",
    p_value: "Price/Book value",
    p_cf: "Price/Cash flow",
    p_sales: "Price/Sales",
    per: "Price/Earnings",
    corp: "Corporate debt",
    public: "Public debt",
    securitised: "Securitised debt",
    years_since_launch : "Years since fund launch",
    size_cat: "Average size of companies in the fund",
    factor_cat: "Investment style of the fund",
    term_cat: "Fixed income term"
  },
  positions_fields: {
    isin_tkr: "ISIN/TKR",
    name: "Name",
    family: "Family",
    currency: "Currency",
    fund_type: "Fund type",
    inverse: "Inverse",
    leverage: "Leverage",
    hedge: "Hedge",
    country: "Country",
    region: "Region",
    morningstar_category: "Morningstar category",
    sector: "Sector",
    pasive: "Pasive",
    size_cat: "Size",
    factor_cat: "Factor",
    term_cat: "Term",
    nan: "nan",
    min_ini_purchase: "Minimum purchase",
    min_ini_purchase_eur: "Minimum purchase EUR",
    shares_cash: "Cash",
    market_cap: "Market cap",
    fund_size: "Fund size",
    fees: "Fees",
    distribution: "Distribution",
    launch_date: "Launch date",
    rat_mstar: "Stars Morningstar",
    error_download: "Error download",
    closed: "Closed",
    clean_class: "Clean class",
    ideas: "Ideas",
    asset_manager_id: "Asset manager id",
    equity_comp: "Equity composition",
    fi_comp: "Fixed income composition",
    per: "P/E",
    p_value: "P/Value Book",
    p_cf: "P/Cash flow",
    p_sales: "P/Sales",
    dividend: "Dividend",
    consumer_cyclical: "Consumer cyclical",
    consumer_defensive: "Consumer defensive",
    energy: "Energy",
    industrials: "Industrials",
    real_estate: "Real estate",
    basics_materials: "Commodities",
    healthcare: "Healthcare",
    financial_service: "Financial services",
    utilities: "Utilities",
    communication_services: "Communication services",
    technology: "Technology",
    asia_developed: "Developed Asia",
    asia_emerging: "Emerging Asia",
    australasia: "Australasia",
    europe_developed: "Developed Europe",
    europe_emerging: "Emerging Europe",
    japan: "Japan",
    latin_america: "LATAM",
    north_america: "North America",
    uk: "United Kingdom",
    africa_middle_east: "Africa and Middle East",
    estimated_duration: "Modified Duration",
    effective_duration: "Effective duration",
    years_since_launch: "Years since launch",
    inv_grade: "Investment grade",
    corp: "Corporate",
    public: "Government",
    securitised: "Securitized",
    fund_manager: "Fund manager",
    last_value_date: "Last value date",
    base_currency: "Base currency",
    avg_volume_10: "Average volume 10D",
    last_mkt_value: "Last market value",
    last_market_value : "Market Price",
    revenue: "Revenues",
    eps: "EPS",
    shares_outstanding: "Outstanding Shares",
    yield: "Yield",
    next_earning_date: "Next earning date",
    inf: "Description",
    industry: "Industry",
    other_type: "Other type",
    group: "Group",
    max_weight: "Max weight",
    min_weight: "Min weight",
    YTD: "YTD EUR",
    "Rent a => 5/2023": "Yield to => 5/2023",
    "Rent 1m": "Yield 1M",
    "Rent 3m": "Yield 3M",
    "Rent 1A": "Yield 1Y",
    "Rent 3A": "Yield 3Y",
    "Vola 12m": "Vol 12M",
    "Sharpe 12m": "Sharpe 12M",
    "Sortino 2m": "Sortino 2M",
    Sortino40: "Sortino 40D",
    Rent_2023: "Yield 2023",
    r_ini: "R Ini",
    titles: "Titles",
    invested: "Invested",
    market_value: "Market value",
    search: "Search",
    unrealized_gains: "Unrealized Gains",
    weight: "Weight",
    port: "Port",
    port_: "Port ",
    port_1: "Port 1",
    ret_1: "Yield 1D",
    ret_5: "Yield 1W",
    ret_10: "Yield 2W",
    ret_22: "Yield 1M",
    ret_44: "Yield 2M",
    ret_65: "Yield 3M",
    ret_130: "Yield 6M",
    ret_260: "Yield 1Y",
    ret_780: "Yield 3Y",
    ret_1300: "Yield 5Y",
    ret_2021: "Yield 2021",
    ret_2022: "Yield 2022",
    ret_2023: "Yield 2023",
    vol_5: "Vol 1W",
    vol_10: "Vol 2W",
    vol_22: "Vol 1M",
    vol_44: "Vol 2M",
    vol_65: "Vol 3M",
    vol_260: "Vol 1Y",
    vol_780: "Vol 3Y",
    vol_1300: "Vol 5Y",
    vol_2021: "Vol 2021",
    vol_2022: "Vol 2022",
    vol_2023: "Vol 2023",
    mxdd_5: "MaxDD 1W",
    mxdd_10: "MaxDD 2W",
    mxdd_22: "MaxDD 1M",
    mxdd_44: "MaxDD 2M",
    mxdd_65: "MaxDD 3M",
    mxdd_260: "MaxDD 1Y",
    mxdd_780: "MaxDD 3Y",
    mxdd_1300: "MaxDD 5Y",
    mxdd_2021: "MaxDD 2021",
    mxdd_2022: "MaxDD 2022",
    mxdd_2023: "MaxDD 2023",
    "mxdd 2m": "MaxDD 2M",
    sortino_44: "Sortino 44",
    sh_44: "Sharpe 44",
    tradable: "Tradable",
    Cash: "Cash",
    Convertible: "Convertible",
    Equity: "Equity",
    "Fixed Income": "Fixed Income",
    Others: "Others",
    Preferred: "Preferred",
    "non_equity": "Non Equity",
    "Effective Duration": "Effective Duration",
    "Modified Duration": "Modified Duration",
    "Effective Maturity": "Effective Maturity",
    "Basic Materials": "Basic Materials",
    "Communication Services": "Communication Services",
    "Consumer Cyclical": "Consumer Cyclical",
    "Consumer Defensive": "Conusmer Defensive",
    Energy: "Energy",
    Financial: "Financial",
    Healthcare: "Healthcare",
    Industrials: "Industrials",
    "Information Technology": "Technology",
    "Real Estate": "Real Estate",
    Utilities: "Utilities",
    Unclassified: "Unclassified",
    Unkown: "Unknown",
    'Lower than B': "Lower than B",
    position_name: "Security Name",
    Total: "Total",
    Argentina: 'Argentina',
    Asia: 'Asia',
    Australia: 'Australia',
    Austria: 'Austria',
    Belgium: 'Belgium',
    Brazil: 'Brazil',
    BRIC: 'BRIC',
    Canada: 'Canada',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    'Czech Republic': 'Czech Republic',
    Denmark: 'Denmark',
    'Eastern Europe': 'Eastern Europe',
    EMEA: 'EMEA',
    'Euro zone': 'Euro zone',
    Europe: 'Europe',
    Finland: 'Finland',
    France: 'France',
    Germany: 'Germany',
    Greece: 'Greece',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungary',
    India: 'India ',
    Indonesia: 'Indonesia',
    Ireland: 'Ireland',
    Israel: 'Israel',
    Italy: 'Italy',
    Malaysia: 'Malaysia',
    MEA: 'MEA',
    Mexico: 'Mexico',
    Netherlands: 'Netherlands',
    'New Zealand': 'New Zealand',
    Nordic: 'Nordic',
    Norway: 'Norway',
    'Nueva Zelanda': 'Nueva Zelanda',
    Pakistan: 'Pakistan',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Poland: 'Poland',
    Portugal: 'Portugal',
    Russia: 'Russia ',
    Singapore: 'Singapore',
    'South Africa': 'South Africa',
    'South Korea': 'South Korea',
    Spain: 'Spain',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Taiwan: 'Taiwan',
    Thailand: 'Thailand',
    Turkey: 'Turkey',
    'United States': 'United States',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    'US Equity': 'US Equity',
    'Africa/Middle East': 'Africa/Middle East',
    'Asia Developed': 'Asia Developed',
    'Asia Emerging': 'Asia Emerging',
    Australasia: 'Australasia',
    'Emerging Europe': 'Emerging Europe',
    'Emerging markets': 'Emerging markets',
    'Europe Developed': 'Europe Developed',
    Global: 'Global',
    Japan: 'Japan',
    'Latin America': 'Latin America',
    'North America': 'North America',
    'United Kingdom': 'United Kingdom',
    'Africa MidEast': 'Africa MidEast',
    'Asia Dev': 'Asia Dev',
    'Asia Emer': 'Asia Emer',
    'Emer Europe': 'Emer Europe',
    'Austral Asia': 'Australasia',
    'Emer Markets': 'Emer Markets',
    'Europe Dev': 'Europe Dev',
    Latam: 'Latam',
    UK: 'UK',
    Biotech: 'Biotech',
    Commodities: 'Commodities',
    created_at: 'Created At',
    updated_at: 'Updated At',
    risk: 'Risk',
    tooltips: {
      Cash: "Cash",
      Convertible: "Convertible",
      Equity: "Equity",
      "Fixed Income": "Fixed Income",
      Others: "Others",
      Preferred: "Preferred",  
    }
  },
  countries: {
    Argentina: 'Argentina',
    Asia: 'Asia',
    Australia: 'Australia',
    Austria: 'Austria',
    Belgium: 'Belgium',
    Brazil: 'Brazil',
    BRIC: 'BRIC',
    Canada: 'Canada',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    'Czech Republic': 'Czech Republic',
    Denmark: 'Denmark',
    'Eastern Europe': 'Eastern Europe',
    EMEA: 'EMEA',
    'Euro zone': 'Euro zone',
    Europe: 'Europe',
    Finland: 'Finland',
    France: 'France',
    Germany: 'Germany',
    Greece: 'Greece',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungary',
    India: 'India',
    Indonesia: 'Indonesia',
    Ireland: 'Ireland',
    Israel: 'Israel',
    Italy: 'Italy',
    Malaysia: 'Malaysia',
    MEA: 'MEA',
    Mexico: 'Mexico',
    Netherlands: 'Netherlands',
    'New Zealand': 'New Zealand',
    Nordic: 'Nordic',
    Norway: 'Norway',
    Pakistan: 'Pakistan',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Poland: 'Poland',
    Portugal: 'Portugal',
    Russia: 'Russia',
    Singapore: 'Singapore',
    'South Africa': 'South Africa',
    'South Korea': 'South Korea',
    Spain: 'Spain',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Taiwan: 'Taiwan',
    Thailand: 'Thailand',
    Turkey: 'Turkey',
    'United States': 'United States',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    'US Equity': 'US Equity',
    'Africa/Middle East': 'Africa/Middle East',
    'Asia Developed': 'Asia Developed',
    'Asia Emerging': 'Asia Emerging',
    Australasia: 'Australasia',
    'Emerging Europe': 'Emerging Europe',
    'Emerging markets': 'Emerging markets',
    'Europe Developed': 'Europe Developed',
    Global: 'Global',
    Japan: 'Japan',
    'Latin America': 'Latin America',
    'North America': 'North America',
    'United Kingdom': 'United Kingdom',
    'Africa MidEast': 'Africa MidEast',
    'Asia Dev': 'Asia Dev',
    'Asia Emer': 'Asia Emer',
    'Emer Europe': 'Emer Europe',
    'Austral Asia': 'Australasia',
    'Emer Markets': 'Emer Markets',
    'Europe Dev': 'Europe Dev',
    Latam: 'Latam',
    UK: 'UK',
  },
  regions: {
    Argentina: 'Argentina',
    Asia: 'Asia',
    Australia: 'Australia',
    Austria: 'Austria',
    Belgium: 'Belgium',
    Brazil: 'Brazil',
    BRIC: 'BRIC',
    Canada: 'Canada',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    'Czech Republic': 'Czech Republic',
    Denmark: 'Denmark',
    'Eastern Europe': 'Eastern Europe',
    EMEA: 'EMEA',
    'Euro zone': 'Euro zone',
    Europe: 'Europe',
    Finland: 'Finland',
    France: 'France',
    Germany: 'Germany',
    Greece: 'Greece',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungary',
    India: 'India',
    Indonesia: 'Indonesia',
    Ireland: 'Ireland',
    Israel: 'Israel',
    Italy: 'Italy',
    Malaysia: 'Malaysia',
    MEA: 'MEA',
    Mexico: 'Mexico',
    Netherlands: 'Netherlands',
    'New Zealand': 'New Zealand',
    Nordic: 'Nordic',
    Norway: 'Norway',
    Pakistan: 'Pakistan',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Poland: 'Poland',
    Portugal: 'Portugal',
    Russia: 'Russia',
    Singapore: 'Singapore',
    'South Africa': 'South Africa',
    'South Korea': 'South Korea',
    Spain: 'Spain',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Taiwan: 'Taiwan',
    Thailand: 'Thailand',
    Turkey: 'Turkey',
    'United States': 'United States',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    'US Equity': 'US Equity',
    'Africa/Middle East': 'Africa/Middle East',
    'Asia Developed': 'Asia Developed',
    'Asia Emerging': 'Asia Emerging',
    Australasia: 'Australasia',
    'Emerging Europe': 'Emerging Europe',
    'Emerging markets': 'Emerging markets',
    'Europe Developed': 'Europe Developed',
    Global: 'Global',
    Japan: 'Japan',
    'Latin America': 'Latin America',
    'North America': 'North America',
    'United Kingdom': 'United Kingdom',
    'Africa MidEast': 'Africa MidEast',
    'Asia Dev': 'Asia Dev',
    'Asia Emer': 'Asia Emer',
    'Emer Europe': 'Emer Europe',
    'Austral Asia': 'Australasia',
    'Emer Markets': 'Emer Markets',
    'Europe Dev': 'Europe Dev',
    Latam: 'Latam',
    UK: 'UK',
  },
  sectors: {
    "Basic Materials": "Basic Materials",
    Biotech: "Biotech",
    Commodities: "Commodities",
    "Communication Services": "Communication Services",
    Consumer: "Consumer",
    "Consumer Cyclical": "Consumer Cyclical",
    "Consumer Defensive": "Consumer Defensive",
    Energy: "Energy",
    Environment: "Environment",
    Financial: "Financial",
    Gold: "Gold",
    Healthcare: "Healthcare",
    Industrials: "Industrials",
    "Information Technology": "Information Technology",
    Infrastructure: "Infrastructure",
    Megatrends: "Megatrends",
    Mobility: "Mobility",
    "Natural resources": "Natural resources",
    "Real Estate": "Real Estate",
    Utilities: "Utilities",
  },
  sizes: {
    Small: "Small Cap",
    "Mid/Small": "Small/Mid Cap",
    Mid: "Mid Cap",
    Flex: "Flexible Cap",
    Big: "Big Cap",
  },
  factors: {
    Growth: "Growth",
    Blend: "Blend",
    Value: "Value",
    "High dividend": "High dividend"
  },
  altTypes: {
    "Alt conservative": "Alt conservative",
    "Alt moderate": "Alt moderate",
    "Alt dynamic": "Alt dynamic",
    "Alt aggressive": "Alt aggressive",
    Inverse: "Inverse",
    "Fixed income inverse": "Fixed income inverse",
    Guaranteed: "Guaranteed",
  },
  mixTypes: {
    "Mix moderate": "Mix moderate",
    "Mixed conservative": "Mixed conservative",
    "Mix dynamic": "Mix dynamic",
    "Mixed aggressive": "Mixed aggressive",
  },
  termCats: {
    "Ultra short": "Ultra short",
    Short: "Short",
    Mid: "Mid",
    Flexible: "Flexible",
    Long: "Long",
  },
  rentasRF: {
    "Money Market": "Money Market",
    Government: "Government",
    "High yield": "High yield",
    Convertible: "Convertible",
    Corporate: "Corporate",
    "Inflation-Linked": "Inflation-Linked",
    Subordinated: "Subordinated",
    Diversified: "Diversified",
  },
  industries: {
    "Advertising Agencies": "Advertising Agencies",
    "Aerospace & Defense": "Aerospace & Defense",
    "Agricultural Inputs": "Agricultural Inputs",
    "Airlines": "Airlines",
    "Airports & Air Services": "Airports & Air Services",
    "Aluminum": "Aluminum",
    "Apparel Manufacturing": "Apparel Manufacturing",
    "Apparel Retail": "Apparel Retail",
    "Asset Management": "Asset Management",
    "Auto & Truck Dealerships": "Auto & Truck Dealerships",
    "Auto Manufacturers": "Auto Manufacturers",
    "Auto Parts": "Auto Parts",
    "Automobiles & Auto Parts": "Automobiles & Auto Parts",
    "Banks - Diversified": "Banks - Diversified",
    "Banks - Regional": "Banks - Regional",
    "Beverages - Brewers": "Beverages - Brewers",
    "Beverages - Non-Alcoholic": "Beverages - Non-Alcoholic",
    "Beverages - Wineries & Distilleries": "Beverages - Wineries & Distilleries",
    "Biotechnology": "Biotechnology",
    "Broadcasting": "Broadcasting",
    "Building Materials": "Building Materials",
    "Building Products & Equipment": "Building Products & Equipment",
    "Business Equipment & Supplies": "Business Equipment & Supplies",
    "Capital Markets": "Capital Markets",
    "Chemicals": "Chemicals",
    "Communication Equipment": "Communication Equipment",
    "Communications & Networking": "Communications & Networking",
    "Computer Hardware": "Computer Hardware",
    "Confectioners": "Confectioners",
    "Conglomerates": "Conglomerates",
    "Construction & Engineering": "Construction & Engineering",
    "Consulting Services": "Consulting Services",
    "Consumer Electronics": "Consumer Electronics",
    "Copper": "Copper",
    "Credit Services": "Credit Services",
    "Department Stores": "Department Stores",
    "Diagnostics & Research": "Diagnostics & Research",
    "Discount Stores": "Discount Stores",
    "Drug Manufacturers - General": "Drug Manufacturers - General",
    "Drug Manufacturers - Specialty & Generic": "Drug Manufacturers - Specialty & Generic",
    "Education & Training Services": "Education & Training Services",
    "Electrical Equipment & Parts": "Electrical Equipment & Parts",
    "Electrical Utilities & IPPs": "Electrical Utilities & IPPs",
    "Electronic Components": "Electronic Components",
    "Electronic Gaming & Multimedia": "Electronic Gaming & Multimedia",
    "Electronics & Computer Distribution": "Electronics & Computer Distribution",
    "Engineering & Construction": "Engineering & Construction",
    "Entertainment": "Entertainment",
    "Farm & Heavy Construction Machinery": "Farm & Heavy Construction Machinery",
    "Farm Products": "Farm Products",
    "Financial Conglomerates": "Financial Conglomerates",
    "Financial Data & Stock Exchanges": "Financial Data & Stock Exchanges",
    "Food Distribution": "Food Distribution",
    "Footwear & Accessories": "Footwear & Accessories",
    "Furnishings, Fixtures & Appliances": "Furnishings, Fixtures & Appliances",
    "Gambling": "Gambling",
    "Gold": "Gold",
    "Grocery Stores": "Grocery Stores",
    "Health Information Services": "Health Information Services",
    "Healthcare Plans": "Healthcare Plans",
    "Home Improvement Retail": "Home Improvement Retail",
    "Household & Personal Products": "Household & Personal Products",
    "Industrial Distribution": "Industrial Distribution",
    "Information Technology Services": "Information Technology Services",
    "Infrastructure Operations": "Infrastructure Operations",
    "Insurance": "Insurance",
    "Insurance - Diversified": "Insurance - Diversified",
    "Insurance - Life": "Insurance - Life",
    "Insurance - Property & Casualty": "Insurance - Property & Casualty",
    "Insurance - Reinsurance": "Insurance - Reinsurance",
    "Insurance - Specialty": "Insurance - Specialty",
    "Insurance Brokers": "Insurance Brokers",
    "Integrated Freight & Logistics": "Integrated Freight & Logistics",
    "Internet Content & Information": "Internet Content & Information",
    "Internet Retail": "Internet Retail",
    "Investment Holding Companies": "Investment Holding Companies",
    "Leisure": "Leisure",
    "Lodging": "Lodging",
    "Lumber & Wood Production": "Lumber & Wood Production",
    "Luxury Goods": "Luxury Goods",
    "Machinery, Tools, Heavy Vehicles, Trains & Ships": "Machinery, Tools, Heavy Vehicles, Trains & Ships",
    "Marine Shipping": "Marine Shipping",
    "Medical Care Facilities": "Medical Care Facilities",
    "Medical Devices": "Medical Devices",
    "Medical Distribution": "Medical Distribution",
    "Medical Instruments & Supplies": "Medical Instruments & Supplies",
    "Metal Fabrication": "Metal Fabrication",
    "Mortgage Finance": "Mortgage Finance",
    "Oil & Gas Drilling": "Oil & Gas Drilling",
    "Oil & Gas E&P": "Oil & Gas E&P",
    "Oil & Gas Equipment & Services": "Oil & Gas Equipment & Services",
    "Oil & Gas Integrated": "Oil & Gas Integrated",
    "Oil & Gas Midstream": "Oil & Gas Midstream",
    "Oil & Gas Refining & Marketing": "Oil & Gas Refining & Marketing",
    "Other": "Other",
    "Other Industrial Metals & Mining": "Other Industrial Metals & Mining",
    "Other Precious Metals & Mining": "Other Precious Metals & Mining",
    "Packaged Foods": "Packaged Foods",
    "Packaging & Containers": "Packaging & Containers",
    "Paper & Paper Products": "Paper & Paper Products",
    "Personal Services": "Personal Services",
    "Pharmaceutical Retailers": "Pharmaceutical Retailers",
    "Pollution & Treatment Controls": "Pollution & Treatment Controls",
    "Publishing": "Publishing",
    "Railroads": "Railroads",
    "Real Estate - Development": "Real Estate - Development",
    "Real Estate - Diversified": "Real Estate - Diversified",
    "Real Estate Services": "Real Estate Services",
    "Recreational Vehicles": "Recreational Vehicles",
    "REIT - Diversified": "REIT - Diversified",
    "REIT - Healthcare Facilities": "REIT - Healthcare Facilities",
    "REIT - Hotel & Motel": "REIT - Hotel & Motel",
    "REIT - Industrial": "REIT - Industrial",
    "REIT - Mortgage": "REIT - Mortgage",
    "REIT - Office": "REIT - Office",
    "REIT - Residential": "REIT - Residential",
    "REIT - Retail": "REIT - Retail",
    "REIT - Specialty": "REIT - Specialty",
    "Rental & Leasing Services": "Rental & Leasing Services",
    "Residential Construction": "Residential Construction",
    "Resorts & Casinos": "Resorts & Casinos",
    "Restaurants": "Restaurants",
    "Scientific & Technical Instruments": "Scientific & Technical Instruments",
    "Security & Protection Services": "Security & Protection Services",
    "Semiconductor Equipment & Materials": "Semiconductor Equipment & Materials",
    "Semiconductors": "Semiconductors",
    "Semiconductors & Semiconductor Equipment": "Semiconductors & Semiconductor Equipment",
    "Software - Application": "Software - Application",
    "Software - Infrastructure": "Software - Infrastructure",
    "Software-Application": "Software-Application",
    "Solar": "Solar",
    "Specialty Business Services": "Specialty Business Services",
    "Specialty Chemicals": "Specialty Chemicals",
    "Specialty Industrial Machinery": "Specialty Industrial Machinery",
    "Specialty Retail": "Specialty Retail",
    "Staffing & Employment Services": "Staffing & Employment Services",
    "Steel": "Steel",
    "Telecom Services": "Telecom Services",
    "Textile Manufacturing": "Textile Manufacturing",
    "Thermal Coal": "Thermal Coal",
    "Tobacco": "Tobacco",
    "Tools & Accessories": "Tools & Accessories",
    "Travel Services": "Travel Services",
    "Trucking": "Trucking",
    "Uranium": "Uranium",
    "Utilities - Diversified": "Utilities - Diversified",
    "Utilities - Independent Power Producers": "Utilities - Independent Power Producers",
    "Utilities - Regulated Electric": "Utilities - Regulated Electric",
    "Utilities - Regulated Gas": "Utilities - Regulated Gas",
    "Utilities - Regulated Water": "Utilities - Regulated Water",
    "Utilities - Renewable": "Utilities - Renewable",
    "Utilities-Regulated Electric": "Utilities-Regulated Electric",
    "Waste Management": "Waste Management",
  },
  benchmark_categories: {
    General: "General",
    Sector: "Sector",
    Synthetic: "Synthetic",
    Equity :"Equity",
    Commodity : "Commodity",
    Bond : "Bond",
    Volatility : "Volatility"
  },
  pension_categories: {
    Mixed: "Mixed",
    Fixed: "Fixed",
    Equity: "Equity"
  },
  risks: {
    Medio: "Middle",
    "Muy reducido": "Very reduced",
    "Medio/Bajo": "Middle/Low",
    Alto: "High",
    Reducido: "Reduced",
    "Muy alto": "Very High",
    "Medio/Alto": "Middle/High",
    null: "Null",
    Bajo: "Low",
  },
  legal_structures: {
    null: "Null",
    SICAV: "SICAV",
    "Unit Trust": "Unit Trust",
    "Open Ended Investment Company": "Open Ended Investment Company",
    FCP: "FCP",
  },
};
